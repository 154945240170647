body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-theme-alpine {
  --ag-borders: none;
  --ag-row-border-style: none;
  --ag-header-foreground-color: #d5d5e0;
}

.ag-theme-alpine .ag-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  border-radius: 15px;
}

.ag-theme-alpine .ag-header-group-cell {
  font-size: 14px;
}

.ag-theme-alpine .ag-header-cell {
  background-color: #d5d5e0;
  color: black;
}

.ag-cell {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.divCircle {
  display: inline-block;
  height: 36px;
  width: auto;
  border-radius: 50%;
  border: 1px solid #ddd;
  text-align: center;
  background-size: cover;
}

.release-btn {
  background: #0d0359;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
}

.update-status-wrapper {
  padding: 0px !important;

  .btn-update {
    white-space: nowrap;
    position: relative;
  }

  .update-status-button {
    max-width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .option-menu-wrapper {
    position: absolute;
    z-index: 99999;
    padding: 0px !important;
    background: #fff;
    width: fit-content;
    margin-left: -120px;
    margin-top: 2px;
    box-shadow: 0px 0px 12px 0px rgba(213, 213, 224, 0.6);
    border-radius: 8px;
    border: 1px solid rgba(83, 62, 237, 1);

    .option-menu {
      padding: 0;

      .option-menu-items {
        padding: 0.75rem;
        font-size: 1rem;
        line-height: 1.75rem;
        color: rgba(34, 37, 41, 1);
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background: linear-gradient(0deg, #533eed, #533eed),
            linear-gradient(0deg, #ffffff, #ffffff);
          color: #fff;
        }
      }
    }
  }
}

.no-flex {
  display: flex;
  flex-direction: column;
}

.manager-release-table-container {
  position: relative;
  .list-table {
    .list-header {
      padding-left: 2px;
      white-space: normal;
      .empname-header-cont {
        min-width: 175px;
      }
    }
    .list-data {
      padding-left: 2px;
      white-space: nowrap;
      div {
        padding: 20px 8px;
      }
      .empname {
        white-space: normal;
        min-width: 175px;
        .empimg {
          width: 36px;
          height: 36px;
        }
        .emp-name {
          font-family: "Barlow";
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(83, 62, 237, 1);
        }
      }
    }
  }
}
