.self-summary-container {
  margin-right: 2rem;
  .header-pro {
    position: relative;
  }
  h2 {
    font-family: "Barlow";
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    color: #0d0359;
    margin-top: 0px;
  }
  .skills-wrapper-summary {
    margin-top: 1rem;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: var(--ui-color-white, #fff);
    h2 {
      margin-bottom: 0px !important;
    }
    h3 {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  .self-assessment-form-container,
  .pre-release-task-container {
    margin: 1rem 0px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: var(--ui-color-white, #fff);
  }
  .button-wrapper {
    margin: 1rem 0px;
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8px;
    background: var(--ui-color-white, #fff);
    .modal-button {
      background-color: #0fe4bd;
      color: #0d0359;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      float: right;
      margin-top: 10px;
    }
    .modal-button.cancel {
      background: #d5d5e0;
      color: #0d0359;
      margin-right: 10px;
    }

    .modal-button:hover {
      background-color: #0056b3;
      color: #fff;
    }
    .disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.table-loader {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-img {
    height: 80px;
    width: 80px;
  }
}
