.content-wrap {
  width: calc(100% - 30px);
  .employee-rr-table-wrapper {
    border-radius: 20px;
    background: #ffffff;
    padding: 8px 5px;
    position: relative;
    margin-top: 1.5rem;
  }
}
