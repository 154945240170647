.login-wrap {
    width: 500px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0d0359;
    border: 1px solid #0d0359;
    border-radius: 8px;
    margin: 30px auto;
}
.login-wrap button {
    background: #0d0359;
    padding: 0px 15px;
    height: 34px;
    line-height: 34px;
    color: #fff;
    margin-top: 30px;
    margin-right: 4px;
    cursor: pointer;
}

.login-button-wrap {
    display: flex;
}