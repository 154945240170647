.empty-record {
  .message-text {
    font-family: "Barlow";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 440px;
  }
}
