
.modal-interview-allocation {
    width: 100% !important;
    .modal-content-allocation {
        width: 100% !important;
        max-width: 880px !important;
        .modal-header-allocation {
            width: 100% !important;
            margin-bottom: 1.5rem;
        }

        .modal-cont-allocation {
            width: calc(100% - 20px) !important;
            padding-top: 0px; 
            margin-bottom: 1rem;        
            .auto-width {
                width: auto !important;
            }
            .profile-img {
                width: 60px;
                border-radius: 50%;
                border: 1px solid #ddd;
            }
            .employee-name {
                padding: 0px 10px;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                color: rgba(13, 3, 89, 1);
            }
            .employee-detail-wrap {
                padding: 0px 10px;
                .employee-detail {
                    padding: 0px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 27px;
                    color: rgba(102, 102, 102, 1);
                    margin-right: 8px;
                }
                .seprator {
                    border: 1px solid rgba(213, 213, 224, 1);
                    width: 16px;
                    margin-right: 8px;
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
            .rr-details-wrap {
                padding: 6px 12px 10px 0px;
                .rr-id {
                    padding: 0px 10px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    color: rgba(34, 37, 41, 1);

                }
                .rr-requirement-wrap {
                    padding: 0px 10px;
                    .rr-req {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: rgba(102, 102, 102, 1);
                        margin-right: 8px;
                    }
                    .seprator {
                        border: 1px solid rgba(213, 213, 224, 1);
                        width: 16px;
                        margin-right: 8px;
                        -webkit-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }
                    .pill {
                        border: 1px solid rgba(213, 213, 224, 1);
                        border-radius: 16px;
                        background: linear-gradient(0deg, #D5D5E0, #D5D5E0), linear-gradient(0deg, #FFFFFF, #FFFFFF);
                        padding: 2px 12px;
                        height: 28px;
                        color: rgba(102, 102, 102, 1);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                    }
                }
            }
        }

        .date-percent-wrapper {
            display: flex;
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding: 0px 10px;
            .date-header {
                padding: 0px;
                font-size: 16px;
                font-weight: 500;
                line-height: 27px;
                color: rgba(102, 102, 102, 1);
                margin-bottom: 0.5rem;
            }
            .date-wrapper {
                width: 50%;
                .date-picker-container {
                    .date-picker {
                        width: 300px !important;
                        height: 46px;
                    }
                }
            }
            .basic-single {
                width: 120%;
                .select__control {
                    .select__indicators {
                        .select__indicator-separator {
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                .select__menu {
                    height: 15rem !important;
                    .select__menu-list {
                        height: 15rem !important;
                        .select__option:hover{
                            cursor: pointer;
                        }
                    }
                    .select__menu-list::-webkit-scrollbar-track {
                        border-radius: 10px;
                    }
                    .select__menu-list::-webkit-scrollbar {
                        width: 4px;
                        height: 2px;
                    }
                    .select__menu-list::-webkit-scrollbar-thumb {
                        border: 2px solid transparent;
                        border-radius: 10px;
                        background-color: grey;
                    }
                }                
            }
        }

        .comment-wrapper {
            width: 60% !important;
            margin-top: 0.5rem;
            padding: 0px 10px;
            .comment-header{
                width: 100%;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .comment-header-main {
                    width:auto !important;
                    padding: 0px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 27px;
                    color: rgba(102, 102, 102, 1);
                }
                .comment-header-limit {
                    width:auto !important;
                    padding: 0px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 27px;
                    color: rgba(102, 102, 102, 1);
                    text-align: end;
                }
            }
            .comment-textarea {
                margin-top: 4px;
                width: calc(100% - 20px) !important;
            }
        }

        .modal-buttons{
            width: calc(100% - 20px) !important;
            text-align: end;
            .modal-button {
                float: initial;
            }
            .loader {
                margin-right: 0.5rem;
            }
            .disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
  }