.modal-interview-add-view-comment {
  width: 100% !important;
  .modal-content-add-view-comment {
    width: 100% !important;
    max-width: 650px !important;
    .modal-header-add-view-comment {
      width: 100% !important;
      margin-bottom: 1.5rem;
    }

    .modal-cont-add-view-comment {
      width: calc(100% - 20px) !important;
      padding-top: 0px;
      margin-bottom: 1rem;
      .auto-width {
        width: auto !important;
      }
      .rr-container {
        margin-top: 0.5rem;
      }
      .seprator {
        border: 1px solid rgba(213, 213, 224, 1);
        width: 16px;
        margin: 0px 8px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      .rr-heading {
        color: #0d0359;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
      }
      .rr-detail-wrap {
        margin-bottom: 0.25rem;
      }
    }

    .comment-container,
    .view-comment-container {
      width: calc(100% - 20px) !important;
      padding-top: 8px;
      margin-bottom: 1rem;
      .comment-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
      .input-field {
        margin-top: 0.5rem;
        .textarea-add-comment {
          border: 1px solid #d5d5e0;
          outline: none;
          resize: none;
          border-radius: 4px;
          width: 80%;
          padding: 0.5rem;
        }
      }
      .view-comment-table-wrapper {
        width: 100%;
        position: relative;
        margin-top: 0.5rem;
        table {
          border-collapse: collapse;
          width: 100%;
          background: #f0f0f4;
        }
        th,
        td {
          border: 1px solid grey;
          padding: 6px; /* Adjust padding as needed */
          text-align: left;
          font-weight: 400;
          // word-break: break-word;
        }
        th {
          background-color: #e9e9e9;
          font-weight: 600;
        }
        tr:nth-child(even) {
          background-color: #fff; /* White background for even rows */
        }
      }
      .comment-placeholder {
        font-size: 16px;
        font-weight: 500;
        color: grey;
        text-align: center;
        margin-top: 1.5rem;
      }
    }

    .modal-buttons {
      width: calc(100% - 20px) !important;
      text-align: end;
      .modal-button {
        float: initial;
      }
      .loader {
        margin-right: 0.5rem;
      }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      .mr-5 {
        margin-right: -5px;
      }
    }
  }
}
