/* lightbox-overlay */
.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  /* lightbox-container */
  .lightbox-container {
    max-width: 880px;
    width: 90%;
    max-height: 90%;
    background-color: #fff;
    overflow: auto;
    border-radius: 12px;
  }
  /* CSS */
// /* Hide the default scrollbar */
// .lightbox-container::-webkit-scrollbar {
//   width: 6px;
// }

// /* Track */
// .lightbox-container::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// .lightbox-container::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// .lightbox-container::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

  /* lightbox-content */
  .lightbox-content {
    padding: 35px 25px 25px 25px;
    position: relative;
  }
  
  /* lightbox-close */
  .lightbox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: #666666;
    cursor: pointer;
    border-radius: 6px;
    padding: 0px 6px;
    height: 25px;
  }
  
  /* lightbox-images */
  .lightbox-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  /* lightbox-images img */
  .lightbox-images img {
    max-width: 100%;
    max-height: 80vh;
    margin: 10px;
  }
  