  .tab-buttons button {
    background: #fff;
    border: 1px solid #D5D5E0;
    border-radius: 50px;
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .tab-buttons button.active {
    background: #533EED;
    color: #fff;
  }
  .search.loader{
    height: 25px;
  }