.modal-interview-view-history {
  width: 100% !important;

  .history-rr-details {
    height: 140px;

    .modal-cont-history-pro {
      width: calc(100% - 20px) !important;
      padding-top: 0px;
      margin-bottom: 1rem;
      .auto-width {
        width: auto !important;
      }
      .profile-img {
        width: 60px;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
      .employee-name {
        padding: 0px 10px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        color: rgba(13, 3, 89, 1);
      }
      .employee-detail-wrap {
        padding: 0px 10px;
        .employee-detail {
          padding: 0px;
          font-size: 14px;
          font-weight: 500;
          line-height: 27px;
          color: rgba(102, 102, 102, 1);
          margin-right: 8px;
        }
        .seprator {
          border: 1px solid rgba(213, 213, 224, 1);
          width: 16px;
          margin-right: 8px;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
      .rr-details-wrap {
        padding: 6px 12px 10px 0px;
        .rr-id {
          padding: 0px 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: rgba(34, 37, 41, 1);
        }
        .rr-requirement-wrap {
          padding: 0px 10px;
          .rr-req {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            margin-right: 8px;
          }
          .seprator {
            border: 1px solid rgba(213, 213, 224, 1);
            width: 16px;
            margin-right: 8px;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
          }
          .pill {
            border: 1px solid rgba(213, 213, 224, 1);
            border-radius: 16px;
            background: linear-gradient(0deg, #d5d5e0, #d5d5e0),
              linear-gradient(0deg, #ffffff, #ffffff);
            padding: 2px 12px;
            height: 28px;
            color: rgba(102, 102, 102, 1);
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }

  .modal-content-view-history {
    width: 100% !important;
    max-width: 650px !important;
    max-height: 80vh;
    .modal-header-view-history {
      width: 100% !important;
      margin-bottom: 1.5rem;
    }

    .modal-cont-content-wrapper {
      margin-top: 1rem;
      max-height: calc(80vh - 200px);
      overflow-y: auto;
      .loader-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 200px;
      }
      .view-history-table-wrapper {
        width: 100%;
        position: relative;
        margin-top: 0.5rem;
        table {
          border-collapse: collapse;
          width: 100%;
          background: #f0f0f4;
        }
        th,
        td {
          border: 1px solid grey;
          padding: 6px; /* Adjust padding as needed */
          text-align: left;
          font-weight: 400;
        }
        th {
          background-color: #e9e9e9;
          font-weight: 600;
        }
        tr:nth-child(even) {
          background-color: #fff; /* White background for even rows */
        }
      }
    }

    .modal-cont-content-wrapper::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    .modal-cont-content-wrapper::-webkit-scrollbar {
      width: 4px;
      height: 2px;
    }
    .modal-cont-content-wrapper::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 10px;
      background-color: grey;
    }

    .modal-cont-view-history {
      width: calc(100% - 20px) !important;
      padding-top: 0px;
      margin-bottom: 1rem;
      .auto-width {
        width: auto !important;
      }
      .rr-container {
        margin-top: 0.5rem;
      }
      .seprator {
        border: 1px solid rgba(213, 213, 224, 1);
        width: 16px;
        margin: 0px 8px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      .rr-heading {
        color: #0d0359;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
      }
      .rr-detail-wrap {
        margin-bottom: 0.25rem;
      }
    }

    .history-modal-button {
      width: calc(100% - 20px) !important;
      text-align: end;
      height: 50px;
      .modal-button {
        float: initial;
      }
      .loader {
        margin-right: 0.5rem;
      }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      .mr-5 {
        margin-right: -5px;
      }
    }
  }
}
