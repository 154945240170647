.dashcontainer{
    background-color: #f7f7f9;
    width: 100%; 
    min-height: 100vh;
    max-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    .left-panel {
        display: flex;
        flex-direction: column;
        background-color: #0D0359;
        min-height: 500px;
        padding: 30px 20px;
        max-width: 220px;
      }
      
      .right-panel {
        display: flex;
        flex-direction: column;
        flex: 8;
        padding: 20px 0px;

        .loaderWrap{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader img {  
  animation: spin 2s linear infinite;
}
.empty-record {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-weight: 600;
}