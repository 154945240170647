.rrs-container .filters{
    margin-left: auto;
}
.rrs-container .page-header{
    margin-bottom: 20px;
}
.rrs-container .page-header h1{
    margin-bottom: 0;
}
.rrs-container .page-header img{
    margin-right: 10px;
}
.rrs-content-wrap{
    display: flex;
    flex-direction: row;
    gap:15px;
}
.col-one{
    min-width: 270px;
    max-height: 550px;
    overflow-y: auto;
    width: 20%;
    scrollbar-width: thin;
}
/* For webkit-based browsers */
/* You may need to adjust the width and height to suit your design */
/* This will style both vertical and horizontal scrollbars */
.col-one::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .col-one::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }
  
  .col-one::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
  
  
  
  .col-one::-webkit-scrollbar,
  .col-one::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
  }
  
  .col-one .card{
    margin-bottom: 20px;
    box-shadow: none;
    width: 100%;
  }
  
 .rrs-content {    
    display: flex;
    flex-direction: row;
}
.rrs-content .left-cont{
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
    min-width: 300px;
}
.rrs-content .left-cont ul{
    margin: 0px;
    padding: 0px;
}

.rrs-content .left-cont li{
    list-style: none;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.rrs-content .left-cont img{
    margin-right: 10px;
}
.rrs-content .left-cont li b{
    font-weight: bold;
}
.rrs-content .left-cont p{
    margin: 0;
}
.rrs-content .left-cont .skills p{
    word-wrap: break-word;
    white-space: nowrap;
}
.rrs-content .left-cont {
    border: 1px solid #D5D5E0;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    margin-left:7px;
    margin-bottom: 7px;
}
.rr-logo {
    background: #EDEDF0;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.rr-logo span{
    font-size: 22px;
    color: #533EED;
    font-weight:500;
}
.rrs-content .right-cont{
    background: #FFFFFF99;
    padding: 30px;
    
}
.rrs-content h2{
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
}
.rrs-content .right-cont p, .rrs-content .right-cont ul li{    
font-size: 14px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #666;
}
.applied-rrs .apply-btn,.apply-btn:disabled{
  background-color: #d4d4d4;
  color: #666666;
  pointer-events: none;
}
.right-panel.rrs .allapplied, .right-panel.manager-rrs .allapplied, .right-panel.rrs .all-mangerrrs{
    display: none;
}
.right-panel.applied-rrs .alljobs,.right-panel.applied-rrs .all-mangerrrs{
    display: none;
}
.right-panel.manager-rrs .applied-rrs,.right-panel.manager-rrs .all-mangerrrs{
    display: none;
}
.right-panel.manager-my-rrs .alljobs,.right-panel.manager-my-rrs .allapplied, .right-panel.manager-my-rrs .apply-btn {
    display: none;
}
.applied-rrs .apply-btn{
    display: none;
}
.apply-btn.show{
    display: block;
    background: #0FE4BD;
    color: #0D0359;
    pointer-events: unset;
    margin-right: 10px;
}
.filters span {
    margin: 0px;
}

.withdraw-reason {
    width: auto !important;
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    color: rgba(102, 102, 102, 1);
    .required-field {
        color: red;
    }
}

.myh2:hover {
    overflow: visible;
    text-overflow: ellipsis;  
    background-color: #333;
    color: #fff;    
    padding: 5px 5px 45px 5px;
    border-radius: 5px;   
    white-space: wrap;   
    position: relative;
    margin: -9px 9px 6px 6px;
    
  }
  

 